import React, { useState, useContext } from "react";
import { Modal, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Input from "../../components/Input";
import baseUrl from "../../utils/baseUrl";
import { Formik, Form, ErrorMessage } from "formik";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { FormState } from "./types";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import routes from "../Routes/routes";
import "./index.scss";
import "antd/dist/antd.css";

const LoginModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [state, setState] = React.useState<FormState>({ state: "input" });
  const { login, forgot } = useContext(EscolaLMSContext);
  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleExit = () => {
    setIsModalVisible(false);
    setForgotPassword(false);
  };

  return (
    <>
      <button
        className="app-button app-button-dark app-button-long app-button--register"
        onClick={showModal}
      >
        Log in<span className="rollover"></span>
      </button>
      <Modal
        title=""
        visible={isModalVisible}
        onCancel={handleExit}
        bodyStyle={{ backgroundColor: "white" }}
        footer={
          <div className="login-modal__footer-wrapper">
            <h1>No account?</h1>
            <button
              className="app-button login-modal__footer-button"
              onClick={() => {
                handleExit();
                history.push(routes.authentication);
              }}
            >
              Register here
              <span className="rollover"></span>
            </button>
          </div>
        }
      >
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("E-mail is invalid")
              .required("E-mail address is required"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={(fields) => {
            setState({
              state: "loading",
            });
            forgotPassword
              ? forgot({
                  email: fields.email,
                  return_url: `${baseUrl}/reset-password`,
                })
                  .then(() => {
                    setState({
                      state: "success",
                      message: "We will send you request to reset password",
                    });
                  })
                  .catch((error: any) => {
                    setState({
                      state: "error",
                      message:
                        error?.data?.message ||
                        "Something went wrong. Please contact your administrator",
                      error: error?.data?.errors
                        ? Object.keys(error.data.errors)
                            .map((key) => `${error.data.errors[key]}`)
                            .join("\r\n")
                        : null,
                    });
                  })
              : login(fields)
                  .then(() => {
                    setState({ state: "input" });
                  })
                  .catch((error: any) => {
                    setState({
                      state: "error",
                      message:
                        error?.data?.message ||
                        "Something went wrong. Please contact your administrator",
                      error: error?.data?.errors
                        ? Object.keys(error.data.errors)
                            .map((key) => `${error.data.errors[key]}`)
                            .join("\r\n")
                        : null,
                    });
                  });
          }}
          render={({ errors, touched }) => (
            <Form className="login-modal__form">
              <h1 className="login-modal__header">
                {forgotPassword ? "Remind me my passoword" : "Log in"}
              </h1>
              <div className="form-group">
                <Input
                  name="email"
                  label="E-mail address"
                  isFormik
                  errors={errors.email}
                  touched={touched.email}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              {!forgotPassword && (
                <div className="form-group">
                  <Input
                    name="password"
                    label="Password"
                    type="password"
                    isFormik
                    errors={errors.password}
                    touched={touched.password}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              )}
              <p
                className="login-modal__forgot-password"
                onClick={() => setForgotPassword((prev) => !prev)}
              >
                {forgotPassword
                  ? "Back to the Log in page"
                  : "I forgot my password"}
              </p>
              {state.state === "error" && (
                <Alert type="error" message={state.error || state.message} />
              )}
              <div className="form-group login-modal__button">
                <button className="app-button" type="submit">
                  {forgotPassword ? "Send" : "Login"}
                  {state.state === "loading" && <LoadingOutlined spin />}
                  <span className="rollover"></span>
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
    </>
  );
};

export default LoginModal;
