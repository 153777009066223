import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function useWordCut<T>(collection: T[], objectKey: string, cutWords: string[]) {
  const [cutCollection, setCutCollection] = useState(collection);
  const history = useHistory();

  useEffect(() => {
    const removeClassTags = cutCollection?.filter(
      (collectionItem: Record<string, any>) =>
        !cutWords.includes(collectionItem[objectKey])
    );
    setCutCollection(removeClassTags);
  }, [history.location.search, objectKey]);

  return cutCollection;
}

export default useWordCut;
