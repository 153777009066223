import React, { ReactElement } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import routes from "./routes";

import HomePage from "../../pages/index";
import StaticPage from "../../pages/StaticPage/index";
import NotFoundPage from "../../pages/404";
import TutorPage from "../../pages/tutors/tutor/index";
import CoursesPage from "../../pages/courses";
import CoursePage from "../../pages/courses/course/index";
import CoursePreviewPage from "../../pages/courses/preview";
import RegisterPage from "../../pages/Register/index";
import EmailVerified from "../../pages/email-verified";

// privates
import MyProfilePage from "../../pages/user/my-profile";
import MyCoursesPage from "../../pages/user/my-courses";
import CourseProgramPage from "../../pages/course/index";

import FAQPage from "../../pages/FAQ";
import AuthorsPage from "../../pages/Authors";

import PrivateRoute from "./private";

const Routes: React.FC = (): ReactElement => {
  const {
    home,
    authentication,
    page,
    myProfile,
    myCourses,
    tutors,
    tutor,
    courses,
    course,
    preview,
    courseProgram,
    notFound,
    faq,
    emailVerified,
    emailVerifiedPL,
  } = routes;

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path={home} component={HomePage} />
        <Route exact path={authentication} component={RegisterPage} />
        <Route exact path={tutors} component={AuthorsPage} />
        <Route exact path={tutor} component={TutorPage} />
        <Route exact path={courses} component={CoursesPage} />
        <Route exact path={course} component={CoursePage} />

        <Route exact path={faq} component={FAQPage} />
        <Route exact path={emailVerified} component={EmailVerified}>
          {emailVerified === "/email-verified" && (
            <Redirect to={emailVerifiedPL} />
          )}
        </Route>
        <Route exact path={emailVerifiedPL} component={EmailVerified} />
        {/* privates pages*/}
        <PrivateRoute exact path={myProfile} component={MyProfilePage} />
        <PrivateRoute exact path={myCourses} component={MyCoursesPage} />
        <PrivateRoute
          exact
          path={courseProgram}
          component={CourseProgramPage}
        />

        <Route exact path={notFound} component={NotFoundPage} />

        {/* must be last */}
        <Route exact path={preview} component={CoursePreviewPage} />
        <Route exact path={page} component={StaticPage} />
        <Route exact component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
