import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { API } from "@escolalms/sdk/lib";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { fetchPages, pages } = useContext(EscolaLMSContext);

  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className="footer-area">
      <div className="container-fluid">
        <div className="footer-wrapper">
          <p>© {currentYear} - Wellms by Escola LMS</p>
          {pages?.list?.data &&
            pages.list.data.map((page: API.Page) => (
              <Link to={`/${page.slug}`} key={page.id}>
                ・ {page.title}
              </Link>
            ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
