import React, { useMemo } from "react";
import { API } from "@escolalms/sdk/lib";
import WhiteWrapper from "../_App/WhiteWrapper";
import UserCourse from "./UserCourse";

const StartedCourses: React.FC<{
  startedCourses?: API.CourseProgressItem[];
}> = ({ startedCourses = [] }) => {
  const progressMap = useMemo(() => {
    return (startedCourses || []).reduce(
      (acc: object, curr: API.CourseProgressItem) => {
        return {
          ...acc,
          [curr.course.id ? curr.course.id : -1]: Math.round(
            ((curr.progress || []).reduce(
              (pAcc, pCurr) => (pCurr.status === 1 ? pAcc + 1 : pAcc),
              0
            ) /
              curr.progress.length) *
              100
          ),
        };
      },
      {}
    );
  }, [startedCourses]);

  return (
    <React.Fragment>
      {startedCourses.length > 0 && (
        <div className="profile-courses margin-bottom-50">
          <WhiteWrapper className="">
            <h2>Finish the course you started</h2>
            <div className="row">
              {startedCourses
                .sort((a: API.CourseProgressItem, b: API.CourseProgressItem) =>
                  (a.course.title || "").localeCompare(b.course.title || "")
                )
                .map((item: API.CourseProgressItem) => (
                  <div className="col-lg-12 col-md-12" key={item.course.id}>
                    <UserCourse
                      categories={[]}
                      course={item.course}
                      progress={
                        item?.course?.id &&
                        progressMap?.[item.course.id] &&
                        typeof progressMap[item.course.id] === "number"
                          ? progressMap[item.course.id]
                          : 0
                      }
                    />
                  </div>
                ))}
            </div>
          </WhiteWrapper>
        </div>
      )}
    </React.Fragment>
  );
};

export default StartedCourses;
