import React from "react";
import { API } from "@escolalms/sdk/lib";
import WhiteWrapper from "../_App/WhiteWrapper";
import CourseCard from "../CourseCard";

const AvailableCourses: React.FC<{
  availableCourses?: API.CourseProgressItem[];
}> = ({ availableCourses = [] }) => {
  return (
    <React.Fragment>
      {availableCourses.length > 0 && (
        <div className="ended-courses available margin-bottom-50">
          <WhiteWrapper className="">
            <h2>Available courses</h2>
            <div className="row">
              {availableCourses
                .sort((a: API.CourseProgressItem, b: API.CourseProgressItem) =>
                  (a.course.title || "").localeCompare(b.course.title || "")
                )
                .map((item: API.CourseProgressItem) => (
                  <div className="col-lg-4 col-md-12" key={item.course.id}>
                    <CourseCard isEndedVariant course={item.course} />
                  </div>
                ))}
            </div>
          </WhiteWrapper>
        </div>
      )}
    </React.Fragment>
  );
};

export default AvailableCourses;
