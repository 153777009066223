import React, { useEffect, useContext, useState, ChangeEvent } from "react";

import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

import routes from "../../components/Routes/routes";
import Layout from "../../components/_App/Layout";
import WhiteWrapper from "../../components/_App/WhiteWrapper";

const MyProfile = () => {
  const { user, updateProfile } = useContext(EscolaLMSContext);

  //TODO: fix any
  const [state, setState] = useState<any>(user.value);
  const history = useHistory();

  useEffect(() => {
    if (!user.loading && !user.value) {
      history.push(routes.authentication);
    } else {
      setState({ ...user.value, bio: "bio" });
    }
  }, [history, user]);

  const updateValue = useCallback((key, value) => {
    //TODO: fix any
    setState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChange = useCallback(
    (e: ChangeEvent) => {
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
        const input = e.target as HTMLInputElement | HTMLTextAreaElement;
        updateValue(input.name, input.value);
      }
    },
    [updateValue]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      updateProfile(state);
    },
    [state, updateProfile]
  );

  return (
    <Layout>
      <React.Fragment>
        <div className="ptb-100" style={{ minHeight: "calc(90vh - 79px)" }}>
          <div className="container">
            <div className="row">
              <div className="offset-lg-2 col-lg-8">
                <WhiteWrapper>
                  <h2 className="text-center">{state.email}</h2>
                  <form onSubmit={onSubmit} className="register-form">
                    <div className="form-group">
                      <label htmlFor="">Imię</label>
                      <input
                        className="app-input"
                        value={state?.first_name}
                        onChange={onChange}
                        name="first_name"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Nazwisko</label>
                      <input
                        className="app-input"
                        value={state?.last_name}
                        onChange={onChange}
                        name="last_name"
                      />
                    </div>

                    <div className="text-center">
                      <button className="default-btn" disabled={user.loading}>
                        Zaktualizuj
                      </button>
                    </div>
                  </form>
                </WhiteWrapper>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default MyProfile;
