import React, { useState, useContext } from "react";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import Layout from "../../components/_App/Layout";
import ArrowIcon from "../../icons/Arrow";
import "./index.scss";

const FAQ = () => {
  const { settings } = useContext(EscolaLMSContext);
  const [state, setState] = useState("");
  return (
    <Layout >
      <div className="faq-container">
        <div className="faq-wrapper">
          <h1>Najczęściej zadawane pytania</h1>
          <div className="faq-item__wrapper">
            {settings &&
              settings.faq &&
              Object.keys(settings.faq).map((key) => {
                if (key.slice(0, 1) === "q") {
                  return (
                    <button
                      key={key}
                      className="faq-item"
                      onClick={() => {
                        if (state === key) {
                          setState("");
                        } else {
                          setState(key);
                        }
                      }}
                    >
                      <div className="faq-item__question">
                        <div className="faq-item__icon-container">
                          <div className="faq-item__icon-wrapper">
                            <ArrowIcon
                              className={`faq-item__icon ${
                                state === key ? "faq-item__icon--active" : ""
                              }`}
                              // TODO fixme
                              //@ts-ignore 
                              fill="white"
                            />
                          </div>
                        </div>
                        {settings.faq && settings.faq[key] && <span>{settings.faq[key]}</span>}
                      </div>
                      <div className="faq-item__content">
                        {state === key &&
                          settings.faq && Object.keys(settings.faq).map((insideKey) => {
                            if (
                              key.slice(2, key.length) ===
                                insideKey.slice(2, insideKey.length) &&
                              insideKey.slice(0, 1) === "a"
                            ) {
                              return (
                                <p key={insideKey}>{settings.faq && settings.faq[insideKey]}</p>
                              );
                            }
                            return null;
                          })}
                      </div>
                    </button>
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
