const routeRoutes = {
  home: "/",
  course: "/courses/:id",
  courseProgram: "/course/:id/:lessonID?/:topicID?",
  authentication: "/auth",
  courses: "/courses",
  parents: "/rodzice",
  page: "/:slug",
  myProfile: "/moj-profil",
  myCourses: "/my-courses",
  tutors: "/autorzy",
  tutor: "/autor/:id",
  preview: "/courses/preview/:id/:lessonID?/:topicID?",
  pages: "/strony",
  faq: "/faq",
  contact: "/kontakt",
  privacyPolicy: "/polityka-prywatnosci",
  emailVerified: "/email-verified",
  emailVerifiedPL: "/weryfikacja-email",
  notFound: "/404",
};

export default routeRoutes;
