import React from "react";

import { Player, EditorContextProvider } from "@escolalms/h5p-react";
import type { XAPIEvent } from "@escolalms/h5p-react";

const H5Player: React.FC<{ id: string; onXAPI?: (e: XAPIEvent) => void }> = ({
  id,
  onXAPI,
}) => {
  return (
    <React.Fragment>
      <EditorContextProvider
        url={`${process.env.REACT_APP_PUBLIC_API_URL}/api/hh5p`}
      >
        <Player
          id={id}
          onXAPI={(event: XAPIEvent) => onXAPI && onXAPI(event)}
          styles={[`${window.location.origin}/h5p_overwrite.css`]}
        />
      </EditorContextProvider>
    </React.Fragment>
  );
};

export default H5Player;
