import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/_App/Layout";
import ErrorBox from "../components/ErrorBox/ErrorBox";
const EmailVerified: React.FC = () => {
  return (
    <Layout>
      <ErrorBox
        title="Email verification"
        className="center-text error-box--with-big-padding"
      >
        <p className="mtb-50">Your email was successfully verified.</p>

        <div className="btn-box">
          <Link to="/" className="default-btn">
            <i className="flaticon-home" /> Back to Homepage
          </Link>
        </div>
      </ErrorBox>
    </Layout>
  );
};

export default EmailVerified;
