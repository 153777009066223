import React, { useContext, useEffect, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import { API } from "@escolalms/sdk/lib";
import qs from "query-string";
import { CoursesContext } from "./CoursesContext";

const parseParams = (params: API.CourseParams = {}) => {
  return qs.stringify(params, { arrayFormat: "bracket" });
};

const CoursesProvider: React.FC<{
  onlyFree?: boolean;
}> = ({ onlyFree = true, children }) => {
  const { fetchCourses, courses, user } = useContext(EscolaLMSContext);
  const location = useLocation();
  const { push } = useHistory();

  const [params, setParams] = useState<API.CourseParams>();

  const getApiParams = (params: any = {}): any => {
    const apiParams = {
      ...params,
      per_page: 12,
      // "tag[]":
      //   location.pathname === routes.parents ||
      //   location.pathname === routes.home
      //     ? "Kurs"
      //     : user.value && user.value.id
      //     ? params.tag
      //     : "Kurs",
      // tag:
      //   user.value && user.value.id
      //     ? params.tag
      //     : location.pathname === "/rodzice"
      //     ? "Kurs"
      //     : params.tag,
    };
    // delete apiParams.tag;
    // if (apiParams.klasy) {
    //   apiParams["tag[]"] = [
    //     classAwf[apiParams.klasy],
    //     apiParams["tag[]"],
    //   ].flat();
    // }

    // @ts-ignore TODO

    // if (onlyFree) apiParams.free = true;
    return apiParams;
  };

  useEffect(() => {
    push(`?${parseParams(params)}`, { scroll: false });
    window.scrollTo(0, 0);
  }, [params, push]);

  useEffect(() => {
    if (
      location.search &&
      location.search.split("?")[1] !== parseParams(params)
    ) {
      setParams(qs.parse(location.search, { arrayFormat: "bracket" }));
      fetchCourses(
        getApiParams(qs.parse(location.search, { arrayFormat: "bracket" }))
      );
    } else {
      fetchCourses(getApiParams(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  return (
    // @ts-ignore TODO
    <CoursesContext.Provider value={{ params, setParams, courses, onlyFree }}>
      {children}
    </CoursesContext.Provider>
  );
};

export default CoursesProvider;
