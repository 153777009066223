import Layout from "../components/_App/Layout";
import React from "react";
import { Link } from "react-router-dom";
import ErrorBox from "../components/ErrorBox/ErrorBox";

const Custom404 = () => {
  return (
    <Layout>
      <ErrorBox
        title="Error 404 : Page does not exist"
        className="center-text error-box--with-big-padding"
      >
        <p className="mtb-50">
          The page you are looking for may have been deleted, had its name
          changed or is temporarily unavailable.
        </p>

        <div className="btn-box">
          <Link to="/" className="default-btn">
            <i className="flaticon-home" /> return to home page
          </Link>
        </div>
      </ErrorBox>
    </Layout>
  );
};

export default Custom404;
