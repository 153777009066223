import React, {
  ReactElement,
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PdfPlayer.scss";
import useWidth from "../../../hooks/useWidth";

const PdfPlayer: FunctionComponent<{
  url: string;
  onLoad?: () => void;
}> = ({ url, onLoad }): ReactElement => {
  const [allPages, setAllPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const pdfWrapperRef = useRef(null);
  const pdfWrapperWidth = useWidth(pdfWrapperRef);
  const [pageWidth, setPageWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (currentPage === allPages) {
      onLoad && onLoad();
    }
  }, [allPages, currentPage, onLoad]);

  useEffect(() => {
    setPageWidth(pdfWrapperWidth ? pdfWrapperWidth * 0.9 : undefined);
  }, [pdfWrapperWidth]);

  if (!url) {
    return <p>Brak dokumentu</p>;
  }

  return (
    <div className="pdf-wrapper" ref={pdfWrapperRef}>
      {isMounted && url && (
        <Document
          className="pdf-wrapper__document"
          loading="Loading..."
          onLoadSuccess={({ numPages }) => setAllPages(numPages)}
          file={url}
        >
          <Page
            width={pageWidth}
            className="pdf-wrapper__page"
            pageNumber={currentPage}
          />
        </Document>
      )}

      {allPages && allPages > 1 && (
        <p className="pdf-wrapper__pages">
          <strong>{currentPage}</strong> <span>of</span>{" "}
          <strong>{allPages}</strong>
        </p>
      )}

      {allPages && allPages > 1 && (
        <div className="pdf-wrapper__buttons">
          {currentPage > 1 && (
            <button
              className="default-btn"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
          )}

          {allPages > currentPage && (
            <button
              className="default-btn"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default PdfPlayer;
