import React, { useState, useContext, useEffect } from "react";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import Layout from "../../components/_App/Layout";
import { LoadingOutlined } from "@ant-design/icons";
import { Formik, Form, ErrorMessage, Field } from "formik";

import { message } from "antd";
import Input from "../../components/Input";
import { FormState } from "./types";
import * as Yup from "yup";
import "./index.scss";
import "../../components/Checkbox/index.scss";

type RegisterFormType = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  main_user_group?: string;
  user_groups: number[];
  voivodeship?: string;
  settings: string[];

  agreements?: string[];
};

type RegisterFormSendType = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  settings: { key: string; value: string }[];
  user_groups: number[];
};

const Register = () => {
  const { settings, registerableGroups, fetchRegisterableGroups, register } =
    useContext(EscolaLMSContext);
  const [state, setState] = useState<FormState>({
    state: "input",
  });

  useEffect(() => {
    fetchRegisterableGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const voivodeships = [
    "dolnośląskie",
    "kujawsko-pomorskie",
    "lubelskie",
    "lubuskie",
    "łódzkie",
    "małopolskie",
    "mazowieckie",
    "opolskie",
    "podkarpackie",
    "podlaskie",
    "pomorskie",
    "śląskie",
    "świętokrzyskie",
    "warmińsko-mazurskie",
    "wielkopolskie",
    "zachodniopomorskie",
  ];

  if (state.state === "success") {
    return (
      <Layout>
        <div className="register-container">
          <div className="register-success__wrapper">
            <h1>Thank you</h1>
            <p>
              {/* Na podany w formularzu adres email wysłaliśmy link potwierdzający. */}
              We've emailed you a confirmation link.
            </p>
            <p>
              {/* Otwórz korespondencję i kliknij w link, aby dokończyć proces
              rejestracji. */}
              Please click on the link to verify your email and continue the
              registration process.
            </p>
          </div>
        </div>
      </Layout>
    );
  }

  const changeUserGroups = ({ values }: { values: RegisterFormType }) => {
    values.user_groups = values.user_groups.filter((element) => {
      if (registerableGroups.list) {
        for (let i = 0; i < registerableGroups.list.length; i++) {
          if (
            registerableGroups?.list[i].id === Number(element) &&
            registerableGroups.list[i]?.parent_id ===
              Number(values.main_user_group)
          ) {
            return element;
          }
        }
      }

      return null;
    });
  };

  return (
    <Layout>
      {settings && registerableGroups && (
        <React.Fragment>
          <div className="register-container">
            <div className="register-wrapper">
              <div className="register-wrapper__header">
                <span>Register</span>
              </div>
              <div className="register-content">
                <Formik<RegisterFormType>
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    main_user_group: "",
                    user_groups: [],
                    voivodeship: "",
                    settings: [],
                    agreements: [],
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("Name is required"),
                    last_name: Yup.string().required("Surname is required"),
                    email: Yup.string()
                      .email("E-mail addres is incorrect")
                      .required("E-mail adress is required"),
                    password: Yup.string()
                      .min(6, "Password has to be at least 6 characters")
                      .required("Password is required"),
                    password_confirmation: Yup.string()
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must be same"
                      )
                      .required("Confirm password is required"),
                    // voivodeship: Yup.string().required("Wybierz województwo"),
                    // main_user_group: Yup.string().required(),
                    agreements: Yup.array().test({
                      test: (arr) => {
                        if (arr && settings.consents) {
                          return (
                            arr.length >= Object.keys(settings.consents).length
                          );
                        }
                        return false;
                      },
                    }),
                  })}
                  onSubmit={(fields) => {
                    const submittedFields = {
                      first_name: fields.first_name,
                      last_name: fields.last_name,
                      email: fields.email,
                      password: fields.password,
                      password_confirmation: fields.password_confirmation,
                      user_groups: [],
                      settings: [
                        {
                          key: "voivodeship",
                          value: fields.voivodeship,
                        },
                      ],
                    } as RegisterFormSendType;

                    if (settings.consents) {
                      Object.keys(settings.consents).forEach((key) =>
                        submittedFields.settings.push({
                          key: key,
                          value: fields?.agreements?.includes(key) ? "1" : "0",
                        })
                      );
                    }
                    submittedFields.user_groups.push(
                      Number(fields.main_user_group)
                    );
                    fields.user_groups.forEach((element) => {
                      submittedFields.user_groups.push(Number(element));
                    });

                    setState({ state: "loading" });
                    register(submittedFields)
                      .then((data: object & { message: string }) => {
                        setState({ state: "success", message: data.message });
                      })
                      .catch((error: any) => {
                        error?.data?.errors
                          ? Object.keys(error.data.errors).map((key) =>
                              message.error(`${error.data.errors[key]}`, 5)
                            )
                          : message.error(
                              error?.data?.message ||
                                "Coś poszło nie tak, skontaktuj się z administratorem",
                              3
                            );
                        setState({ state: "input" });
                      });
                  }}
                  render={({ errors, touched, values }) => {
                    if (touched.main_user_group) {
                      changeUserGroups({ values });
                    }

                    return (
                      <Form>
                        {/* <h1>Status użytkownika</h1>
                        <div className="register-radio">
                          {registerableGroups?.list?.map((element: any) => {
                            if (element.name === "Nauczyciele") {
                              return (
                                <div
                                  className="register-radio-tooltip"
                                  data-tip={settings?.register?.meetinga_popup}
                                  key={element.id}
                                >
                                  <Radiobox
                                    label={element.name}
                                    name="main_user_group"
                                    value={element.id}
                                    disabled
                                  />

                                  {settings.register && (
                                    <>
                                      <QuestionCircleOutlined />
                                    </>
                                  )}
                                  <ReactTooltip />
                                </div>
                              );
                            }

                            return (
                              element.parent_id === null && (
                                <React.Fragment key={element.id}>
                                  <Radiobox
                                    label={element.name}
                                    name="main_user_group"
                                    value={element.id}
                                  />
                                  {values.main_user_group ===
                                    String(element.id) && (
                                    <div className="register-radio__wrapper">
                                      <h2>
                                        Wybierz zakres materiałów szkoleniowych
                                      </h2>
                                      <div className="register-radio__content">
                                        {(registerableGroups?.list || [])
                                          .filter(
                                            (innerElement: any) =>
                                              innerElement.parent_id ===
                                              element.id
                                          )
                                          .map((innerElement: any) => {
                                            return (
                                              <React.Fragment
                                                key={innerElement.id}
                                              >
                                                <Checkbox
                                                  id={innerElement.id}
                                                  label={innerElement.name}
                                                  name="user_groups"
                                                />
                                              </React.Fragment>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              )
                            );
                          })}
                          {errors.main_user_group &&
                            touched.main_user_group && (
                              <span className="register-error">
                                Proszę wybrać status użytkownika
                              </span>
                            )}
                        </div> */}
                        <h1 className="h1">User information</h1>
                        <div className="form-group">
                          <Input
                            name="first_name"
                            label="Name"
                            isFormik
                            errors={errors.first_name}
                            touched={touched.first_name}
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            name="last_name"
                            label="Surname"
                            isFormik
                            errors={errors.last_name}
                            touched={touched.last_name}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            name="email"
                            label="E-mail address"
                            isFormik
                            errors={errors.email}
                            touched={touched.email}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            name="password"
                            label="Password"
                            type="password"
                            isFormik
                            errors={errors.password}
                            touched={touched.password}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            name="password_confirmation"
                            label="Repeat your password"
                            type="password"
                            isFormik
                            errors={errors.password_confirmation}
                            touched={touched.password_confirmation}
                          />
                          <ErrorMessage
                            name="password_confirmation"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            name="voivodeship"
                            label="Voivodeship"
                            as="select"
                            isFormik
                            errors={errors.voivodeship}
                            touched={touched.voivodeship}
                          >
                            <option value="" disabled hidden>
                              Choose
                            </option>

                            {voivodeships.map((element, index) => (
                              <option key={index} value={element}>
                                {element}
                              </option>
                            ))}
                          </Input>
                          <ErrorMessage
                            name="voivodeship"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group agreements">
                          {/* TODO: temporary solution */}
                          <label className="checkbox__container">
                            By clicking ‘Create an account’ you herby consent
                            that you have read the{" "}
                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              href="https://klient.escolalms.com/privacy-policy"
                            >
                              Privacy policy
                            </a>
                            .
                            <Field
                              name={"agreements"}
                              value={"consent_1"}
                              type="checkbox"
                            />
                            <span className="checkbox__checkmark"></span>
                          </label>
                          {/* {settings.consents &&
                            Object.keys(settings.consents).map((key, index) => (
                              <React.Fragment key={index}>
                                <Checkbox
                                  label={"settings.consents![key]"}
                                  id={key}
                                  name={"agreements"}
                                />
                              </React.Fragment>
                            ))} */}
                          {errors.agreements && touched.agreements && (
                            <span className="register-error">
                              Please select all consents
                            </span>
                          )}
                        </div>
                        <div className="form-group register-button">
                          <button className="app-button" type="submit">
                            Create an account
                            {state.state === "loading" && (
                              <LoadingOutlined spin />
                            )}
                            <span className="rollover"></span>
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default Register;
