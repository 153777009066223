import React, { useContext } from "react";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import Markdown from "markdown-to-jsx";
import "./index.scss";

const Banner: React.FC = () => {
  const { settings } = useContext(EscolaLMSContext);

  return (
    <div className="banner-container">
      <div className="banner-wrapper">
        <div className="banner-content">
          {settings && settings?.homepage && (
            <Markdown>{settings?.homepage?.banner_text}</Markdown>
          )}
        </div>
        <div>
          <div className="banner-image">
            <div className="banner-image__wrapper">
              {settings && settings?.homepage && (
                <img
                  src={settings?.homepage?.banner_image}
                  alt="image4"
                  className="banner-image__main-img"
                />
              )}

              <div className="banner-image__shape-1" />
              <div className="banner-image__shape-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
